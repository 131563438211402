import { Box, Button, TextField } from '@mui/material';
import ReactMultiDatePicker from 'react-multi-date-picker';
import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash-es';

interface DatePickerProps {
  maxDate?: string | number | Date;
  label: string;
  field: string;
  actualValue: string | null | undefined;
  setActualValue: (value: string | null | undefined) => void;
}

const DatePicker: FC<DatePickerProps> = ({ maxDate, label, field, actualValue, setActualValue, ...restProps }) => {
  const { control, setValue } = useFormContext();
  const refDatePicker = useRef<{ closeCalendar: () => void }>(null);
  const refTempDate = useRef('');
  const inputRef = useRef<HTMLInputElement>(null);

  const onCloseCalendar = useCallback(() => {
    if (refDatePicker.current) {
      refDatePicker.current.closeCalendar();
    }
  }, [refDatePicker]);

  const handleOk = useCallback(() => {
    if (!isEmpty(refTempDate.current)) {
      setActualValue(refTempDate.current);
      setValue(field, refTempDate.current);
    }
    onCloseCalendar();
  }, [field, refTempDate, setValue, onCloseCalendar, setActualValue]);

  const handleCancel = useCallback(() => {
    onCloseCalendar();
    setActualValue(actualValue);
    setValue(field, actualValue);
  }, [field, actualValue, setValue, onCloseCalendar, setActualValue]);

  const handleReset = useCallback(() => {
    onCloseCalendar();
    setActualValue(undefined);
    setValue(field, undefined);
    if (inputRef.current) inputRef.current.value = '';
  }, [field, onCloseCalendar, setValue, setActualValue, inputRef]);

  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        width: '100%',
        minWidth: 180,
        '& .rmdp-container > div': {
          position: 'relative',
          flex: 1,
          display: 'flex',
        },
        '& .rmdp-container': {
          width: '100%',
          display: 'flex !important',
          height: '40px !important',
        },
        '& .rmdp-day.rmdp-today span': {
          backgroundColor: `${t.palette.primary.light} !important`,
        },
        '& .rmdp-week-day': {
          color: t.palette.grey[400],
        },
        '& .rmdp-arrow': {
          border: `solid ${t.palette.grey[500]}`,
          borderWidth: '0 2px 2px 0',
          display: 'inline-block',
          height: '12px',
          marginTop: '5px',
          padding: '2px',
          width: '12px',
        },
        '& .rmdp-header-values': {
          color: '#000',
          margin: 'auto',
          fontWeight: 500,
          fontSize: '16px',
          order: 1,
          display: 'flex',
          justifyContent: 'start',
          flex: 1,
        },
        '& .rmdp-arrow-container.rmdp-left': {
          order: 2,
        },
        '& .rmdp-arrow-container.rmdp-right': {
          order: 3,
        },
        '& .rmdp-header': {
          mt: 0,
          mb: 2,
          py: 1,
          px: 0,
        },
        '& .rmdp-calendar': {
          py: 2,
          px: 2,
          m: 0,
        },
        '& .rmdp-day-picker': {
          p: 0,
        },
        '& .rmdp-day.rmdp-range': {
          boxShadow: 'none',
          backgroundColor: t.palette.primary.main,
        },
        '& .rmdp-day.rmdp-today:not(.rmdp-range)': {
          boxShadow: 'none',
          backgroundColor: t.palette.primary.light,
          borderRadius: '50%',
        },
      })}
    >
      <ReactMultiDatePicker
        arrow={false}
        ref={refDatePicker}
        range
        value={actualValue ? [new Date(actualValue)] : undefined}
        onOpenPickNewDate
        format="YYYY-MM-DD"
        buttons
        dateSeparator="-"
        maxDate={maxDate}
        render={(value, openCalendar) => {
          refTempDate.current = value;
          const handleOpenCalendar = () => {
            openCalendar();
          };

          return (
            <Controller
              name={field}
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    inputRef={inputRef}
                    onClick={handleOpenCalendar}
                    label={label}
                    variant="outlined"
                    size="small"
                    {...restProps}
                    {...field}
                    sx={{ width: '100%' }}
                  />
                );
              }}
            />
          );
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: actualValue ? 'space-between' : 'end', width: '100%', px: 2, py: 1 }}
        >
          {actualValue && (
            <Button onClick={handleReset} sx={{}} size="small" variant="text" color="warning">
              Reset
            </Button>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={handleCancel} sx={{}} size="small">
              Cancel
            </Button>
            <Button onClick={handleOk} sx={{ minWidth: 32 }} size="small">
              Ok
            </Button>
          </Box>
        </Box>
      </ReactMultiDatePicker>
    </Box>
  );
};

export default DatePicker;
