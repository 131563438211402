import { BooleanParam, NumberParam, NumericArrayParam, StringParam, createEnumParam, useQueryParam, withDefault } from 'use-query-params';

const useListParams = () => {
  const [testWinners, setTestWinners] = useQueryParam('test_winners', withDefault(BooleanParam, false));
  const [orderBy, setOrderBy] = useQueryParam('order_by', withDefault(createEnumParam(["created_at"]), "created_at"));
  const [desc, setDesc] = useQueryParam('desc', withDefault(BooleanParam, true));
  const [favourite, setFavourite] = useQueryParam('favourite', withDefault(BooleanParam, false));
  const [tags, setTags] = useQueryParam('tags', withDefault(NumericArrayParam, undefined));
  const [q, setQ] = useQueryParam('q', withDefault(StringParam, undefined));
  const [previewId, setPreviewId] = useQueryParam('preview', withDefault(NumberParam, undefined));
  const [date, setDate] = useQueryParam('date', withDefault(StringParam, undefined));

  return {
    orderBy,
    setOrderBy,

    desc,
    setDesc,

    favourite,
    setFavourite,

    tags,
    setTags,

    q,
    setQ,

    previewId,
    setPreviewId,

    testWinners,
    setTestWinners,

    date,
    setDate,
  };
};

export default useListParams;
