import { getPersistedData, persistData } from '../../../utils/persist';
import type { PersistedSettingsState } from '../types';
import useListParams from '../../../hooks/useListParams';

const PERSISTED_KEY = 'CreativesSettingsState_v2';
const usePersistedSettings = () => {
  const { testWinners } = useListParams();
  const persistedSettingsState = getPersistedData<PersistedSettingsState>(PERSISTED_KEY);
  const persistedValues = persistedSettingsState ? (testWinners ? persistedSettingsState.testWinners : persistedSettingsState.all) : undefined;

  const onPersistValue = (value: unknown) => {
    persistData(PERSISTED_KEY, {
      ...persistedSettingsState,
      ...(testWinners ? {'testWinners': value} : {'all': value})
    })
  }

  return {
    persistedValues,
    onPersistValue
  }
}

export default usePersistedSettings;
