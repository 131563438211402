import getOrderedColumnsToDisplay from '../utils/getOrderedColumnsToDisplay';
import { useFormContext } from 'react-hook-form';
import type { CreativesSettingsState } from '../types';
import { ALL_COLUMNS } from '../constants';
import { useMemo } from 'react';

const useOrderedColumnsToDisplay = () => {
  const { watch } = useFormContext<CreativesSettingsState>();
  const columnsList = watch('columns');
  const columnsOrder = watch('columnsOrder');
  const ordered = getOrderedColumnsToDisplay(columnsList, columnsOrder);

  return useMemo(() => {
    return ordered.map((columnKey) => {
      const { title, apiKey } = ALL_COLUMNS[columnKey];
      return { title, apiKey, columnKey };
    })
  }, [ordered])
}

export default useOrderedColumnsToDisplay
