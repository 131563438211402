import { Box } from "@mui/material";
import { memo } from "react";
import CreativesCounter from "./CreativesCounter";
import CreativesSearchValues from "./CreativesSearchValues";
import FilterByDate from './FilterByDate';
import FilterByNewest from '../../../components/common/FilterByNewest';

const CreativesTopPanel = memo(() => {
  return (
    <Box sx={t => ({
      pr: 1,
      py: 1.5,
      zIndex: 4,
      gap: 2,
      display: 'flex',
      width: '100%',
      alignItems: 'stretch',
      flexDirection: 'column',
      justifyContent: 'start',
      backgroundColor: 'white',
      [t.breakpoints.up('md')]: {
        minHeight: 68,
      },
    })}>
      <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      }}>
        <Box sx={{
          flex: 1,
          maxWidth: 200,
          display: 'flex',
          alignItems: 'center',
        }}>
          <FilterByDate />
        </Box>
        <Box sx={{
          flex: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'end',
        }}>
          <CreativesCounter />
          <FilterByNewest />
        </Box>
      </Box>
      <CreativesSearchValues />
    </Box >
  )
})

CreativesTopPanel.displayName = "CreativesTopPanel";

export default CreativesTopPanel;
