import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { creativesSettingsSchema } from '../validation';
import useListParams from '../../../hooks/useListParams';
import initialColumnsOrder from '../utils/initialColumnsOrder';
import useColumns from './useColumns';
import { useEffect } from 'react';
import usePersistedSettings from './usePersistedSettings';

const useCreativesForm = () => {
  const { testWinners, tags } = useListParams();
  const COLUMNS = useColumns();
  const { persistedValues } = usePersistedSettings();
  const columnsOrder = initialColumnsOrder(COLUMNS);

  const formMethods = useForm({
    resolver: yupResolver(creativesSettingsSchema),
    defaultValues: {
      previewSize: 'medium',
      columns: {},
      columnsOrder: [],
      date: '',
      searchTags: tags ? tags.reduce((collection, tagId) => ({ ...collection, [`${tagId}`]: true }), {}) : {},
      creativesCount: 0,
      ...(persistedValues ?? null)
    },
  });

  const { setValue } = formMethods;

  useEffect(() => {
    if (persistedValues) {
      setValue('columns', persistedValues.columns)
      setValue('columnsOrder', persistedValues.columnsOrder)
      return
    }

    const columnsOrder = initialColumnsOrder(COLUMNS);
    setValue('columns', columnsOrder.reduce<Record<string, boolean>>(
      (collection, { name }) => ({
        ...collection,
        [name.split('.')[1]]: true,
      }),
      {}
    ))
    setValue('columnsOrder', columnsOrder)
  }, [COLUMNS, setValue, persistedValues, testWinners])

  return {
    formMethods,
    columnsOrder
  }
};

export default useCreativesForm
