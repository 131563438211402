import { memo } from 'react';
import type { SyntheticEvent } from 'react';
import { useCallback } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import useListParams from 'hooks/useListParams';
import { queryClient } from 'lib/react-query';

const CreativesTabs = memo(() => {
  const { testWinners, setTestWinners } = useListParams();
  const onChange = useCallback((_: SyntheticEvent, value: string) => {
    setTestWinners(value === '1');
    void queryClient.invalidateQueries({
      queryKey: ['creatives', value === '1' ? 'test_winners' : 'all'],
    });
  }, [setTestWinners]);

  return (
    <Box sx={{
      position: 'sticky',
      top: 0,
      left: 0,
      backgroundColor: 'white',
      zIndex: 7,
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: 1,
      borderColor: 'divider'
    }}>
      <Tabs value={testWinners ? '1' : '0'} onChange={onChange} variant="scrollable" scrollButtons="auto">
        <Tab value="0" label="All" />
        <Tab value="1" label="Test winners" />
      </Tabs>
    </Box>
  )
})

CreativesTabs.displayName = 'CreativesTabs';

export default CreativesTabs
