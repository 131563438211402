import type { SelectChangeEvent } from '@mui/material';
import { Box, styled } from '@mui/material';
import useListParams from "hooks/useListParams";
import type { Option, OptionValue } from 'types/shared';
import Select from './Select';
import { useCallback } from 'react';

const FilterContainer = styled(Box)(({ theme: t }) => `
  ${t.breakpoints.up('sm')} {
    padding-left: 12px;
  };

  ${t.breakpoints.down('sm')} {
    padding-left: 12px;
    width: 100%;
  };
`);

const SORTING_OPTIONS: Option[] = [
  { value: '1', label: 'Newest' },
  { value: '0', label: 'Oldest' },
];

const FilterByNewest = () => {
  const { desc, setDesc, setOrderBy } = useListParams();

  const handleChange = useCallback( (e:  SelectChangeEvent<OptionValue | OptionValue[]>) => {
    setOrderBy('created_at');
    setDesc(e.target.value === '1')
  }, [setDesc, setOrderBy]);

  return (
    <FilterContainer>
      <Select name={'desc'} value={desc ? '1' : '0'} options={SORTING_OPTIONS} handleChange={handleChange} />
    </FilterContainer>
  )
};

export default FilterByNewest;
