import type { FC } from 'react';
import { useEffect } from 'react';
import DatePicker from '../../../components/common/Datepicker';
import useListParams from '../../../hooks/useListParams';
import { useFormContext } from 'react-hook-form';

const FilterByDate: FC = () => {
  const { date, setDate, testWinners } = useListParams();
  const { setValue } = useFormContext();

  useEffect(() => {
      if( !testWinners ) {
        setDate(undefined);
        setValue('date', undefined);
      }
  }, [testWinners, setDate, setValue]);

  return testWinners ? (
    <DatePicker field="date" label="Date" actualValue={date} setActualValue={setDate} />
  ) : null
}

export default FilterByDate
