import type { AllColumnsKey, ColumnOrderObj } from '../types';

const getOrderedColumnsToDisplay = (columns: Record<AllColumnsKey, boolean>, order: ColumnOrderObj[]) => {
  const visibleCols = Object.keys(columns).filter((k) => columns[k as AllColumnsKey]);

  return order.reduce<AllColumnsKey[]>((result, { name }) => {
    const colId = name.split('.')[1] as AllColumnsKey;
    if (visibleCols.includes(colId)) {
      result.push(colId);
    }
    return result;
  }, []);
};

export default getOrderedColumnsToDisplay;
