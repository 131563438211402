import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import type { ListResponse, PaginationParams, SortingParams } from 'lib/api';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { CreativeTableItem, FilteringFields } from './types';
import queryString from 'query-string';

type CreativesParams = Partial<FilteringFields> & PaginationParams & SortingParams;

export const getCreatives = async ({
  params,
  signal,
}: {
  params: CreativesParams;
  signal?: AbortSignal;
}): Promise<ListResponse<CreativeTableItem>> => {
  const res = await apiClient.get<ListResponse<CreativeTableItem>>(`/creatives`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseCreativesOptions<TData> {
  config?: QueryConfig<typeof getCreatives, TData>;
  params: CreativesParams;
}

export const useCreatives = <TData = ListResponse<CreativeTableItem>>({
  config,
  params,
}: UseCreativesOptions<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCreatives({ params, signal }),
    queryKey: ['creatives', params.test_winners ? 'test_winners' : 'all', params] as QueryKey,
  });
};
