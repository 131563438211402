import CreativeTableHead from './CreativeTableHead';
import CreativeTableRow from './CreativeTableRow';
import { TablePagination } from '@mui/material';
import { useModal, usePagination } from 'hooks';
import { useCreatives } from '../api/getCreatives';
import CreativePreviewPopup from './CreativePreviewPopup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useListParams from 'hooks/useListParams';
import { useFormContext } from 'react-hook-form';
import type { AllColumnsKey } from '../types';
import { useCreativeSortingParam } from '../hooks/useCreativeSortingParam';
import handleNumericList from 'utils/handleNumericList';
import { CreativeEmptyTable, CreativeTableBody, CreativeTableContainer } from './CreativeTableComponents';

const ROWS_PER_PAGE_OPTIONS = [15, 25, 50];
const DEFAULT_PER_PAGE = 25;

const CreativeTable = () => {
  const { page, perPage, handlePageChange, handleRowsPerPageChange } = usePagination([], DEFAULT_PER_PAGE);
  const [orderBy, setOrderBy] = useCreativeSortingParam();
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState<number>(-1);
  const { tags, q, date, testWinners, desc, setDesc } = useListParams();
  const { setValue } = useFormContext();

  const handleToggleSort = useCallback(
    (key: AllColumnsKey) => {
      if (key === orderBy) setDesc((prevState) => !prevState);
      else {
        setOrderBy(key);
        setDesc(true);
      }
    },
    [orderBy, setOrderBy, setDesc]
  );

  const { data: creatives } = useCreatives({
    params: {
      limit: perPage,
      offset: page * perPage,
      desc,
      order_by: `${orderBy}`,
      q,
      tags: handleNumericList(tags),
      test_winners: testWinners ? 1 : 0,
      date,
    },
  });

  const { isOpen: isPreviewOpen, close, open } = useModal();

  const handleOpenPreview = useCallback((index: number) => {
    setCurrentPreviewIndex(index);
    open();
  }, [open]);

  useEffect(() => {
    setValue('creativesCount', creatives.count);
  }, [setValue, creatives.count]);

  const rows = useMemo(() => creatives.data.map((cr, index) => (
    <CreativeTableRow openPreview={handleOpenPreview} index={index} key={cr.id} item={cr} />
  )), [creatives.data, handleOpenPreview]);

  return creatives.count ? (
    <>
      {isPreviewOpen && (
        <CreativePreviewPopup
          isOpen={isPreviewOpen}
          handleClose={close}
          creatives={creatives.data}
          currentPreviewIndex={currentPreviewIndex}
          setCurrentPreviewIndex={setCurrentPreviewIndex}
        />
      )}
      <CreativeTableContainer>
        <CreativeTableHead handleToggleSort={handleToggleSort} desc={desc} orderBy={orderBy} />
        <CreativeTableBody>{rows}</CreativeTableBody>
      </CreativeTableContainer>
      <TablePagination
        sx={{
          zIndex: 4,
          height: 52,
          mb: 2,
          width: '100%',
          py: 2,
          background: 'white',
          overflow: 'visible',
        }}
        id="creatives-pagination"
        labelRowsPerPage="Creatives per page:"
        count={creatives.count}
        page={page}
        component="div"
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      />
    </>
  ) : (
    <CreativeEmptyTable />
  );
};

export default CreativeTable;
