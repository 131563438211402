import type { CreativeDetailsModelKeys } from './api/types';
import type {
  ColumnKey,
  ColumnValue,
  PreviewOption,
  AccessOption,
  TestWinnersColumnKey,
  AllColumnsKey,
} from './types';

export const PREVIEW_OPTIONS: PreviewOption[] = [
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
];

export const TEST_WINNERS_COLUMNS: Record<TestWinnersColumnKey, ColumnValue> = {
  roi: { apiKey: 'roi', title: 'Roi' },
  spend: { apiKey: 'spend', title: 'Spend' },
  purchases: { apiKey: 'purchases', title: 'Purchases' },
  tested_date: { apiKey: 'tested_date', title: 'Tested Date' },
  testing_field: { apiKey: 'testing_field', title: 'Testing Field' },
  days: { apiKey: 'days', title: 'Days' },
  notes: { apiKey: 'notes', title: 'Notes' },
}
export const COLUMNS: Record<ColumnKey, ColumnValue> = {
  file_name: { apiKey: 'file_name', title: 'File name' },
  creative_id: { apiKey: 'creative_id', title: 'ID' },
  base_id: { apiKey: 'base_id', title: 'Base ID' },
  creative_type: { apiKey: 'creative_type', title: 'Creative type' },
  idea_source: { apiKey: 'idea_source', title: 'Idea source' },
  idea_type: { apiKey: 'idea_type', title: 'Idea type' },
  social_network: { apiKey: 'social_network', title: 'Social network' },
  branch: { apiKey: 'branch', title: 'Branch' },
  content_type: { apiKey: 'content_type', title: 'Content type' },
  technology: { apiKey: 'technology', title: 'Technology' },
  resolution: { apiKey: 'resolution', title: 'Resolution' },
  gender: { apiKey: 'gender', title: 'Gender' },
  languages: { apiKey: 'languages', title: 'Languages' },
  policy_check: { apiKey: 'policy_check', title: 'Policy check' },
  projects: { apiKey: 'projects', title: 'Project name' },
  customer: { apiKey: 'customer', title: 'Customer name' },
  performer: { apiKey: 'performer', title: 'Performer' },
  model_name: { apiKey: 'model_name', title: 'Model name' },
  model_video_id: { apiKey: 'model_video_id', title: 'Model video Id' },
  finish_date: { apiKey: 'finish_date', title: 'Finish date' },
};

export const ALL_COLUMNS: Record<AllColumnsKey, ColumnValue> = {
  ...COLUMNS,
  ...TEST_WINNERS_COLUMNS,
}

export const CREATIVE_DETAILS_FIELD: Record<CreativeDetailsModelKeys, string> = {
  creative_id: 'ID',
  id: 'ID',
  file_name: 'File Name',
  thumbnail: 'Thumbnail',
  media_link: 'Media Link',
  download_files_link: 'Download Files',
  branch: 'Branch',
  technology: 'Technology',
  resolution: 'Resolution',
  gender: 'Gender',
  languages: 'Languages',
  policy_check: 'Policy Check',
  projects: 'Project Name',
  tags: 'Tags',
  customer: 'Customer',
  base_customer: 'Base Customer',
  performer: 'Performer',
  model_name: 'Model Name',
  model_video_id: 'Model Video ID',
  access: 'Access',
  original_creative_asset: 'Original Creative',
  base_id: 'Base ID',
  creative_type: 'Creative Type',
  idea_source: 'Idea Source',
  idea_type: 'Idea Type',
  social_network: 'Social Network',
  comment: 'Comment',
  original_text: 'Original Text',
  media_type: 'Media Type',
};

export const ACCESS_OPTIONS: AccessOption[] = [
  { value: true, label: 'Available to all' },
  { value: false, label: 'Restricted' },
];
