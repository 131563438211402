import * as yup from 'yup';

const creativesSettingsSchema = yup.object().shape({
  previewSize: yup.string().required('Preview size is required'),
  columns: yup.object().shape({}),
  columnsOrder: yup.array().of(yup.object().shape({
    name: yup.string(),
    value: yup.string()
  })).required(),
  creativesCount: yup.number(),
  searchTags: yup.object(),
  date: yup.string(),
  notes: yup.object().shape({}),
});

export default creativesSettingsSchema;
