import type { FC } from 'react';
import { memo, useCallback, useState } from 'react';
import {
  Box, Button,
  IconButton, Link,
  styled,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { ContentCopy as ContentCopyIcon, Create } from '@mui/icons-material';
import type { AllColumnsKey } from '../types';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import type { CreativeTableItem } from '../api/types';
import useEnqueueSnackbar from '../../../hooks/useEnqueueSnackbar';
import { useUpdateCreative } from '../api/updateCreative';
import { useFormContext } from 'react-hook-form';
import Input from '../../../components/common/Input';

export const CreativeTableContainer: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <TableContainer
      sx={{
        position: 'relative',
        height: '100%',
        mx: 'auto',
      }}
    >
      <Table
        sx={(t) => ({
          '&': {
            position: 'absolute',
            maxHeight: '100%',
            border: '1px solid',
            borderTop: 'none',
            borderRight: 'none',
            borderColor: t.palette.grey[200],
          },
          'tr th:first-of-type, td:first-of-type': {
            position: 'sticky',
            width: 64,
            left: 0,
            background: 'white',
            borderLeft: 'none',
          },
          'tr th:last-of-type, td:last-of-type': {
            position: 'sticky',
            width: 56,
            right: 0,
            background: 'white',
            borderRightWidth: '1px',
            borderRightStyle: 'solid',
            borderRightColor: t.palette.grey[200],
          },
        })}
        stickyHeader
        size="small"
      >
        {children}
      </Table>
    </TableContainer>
  );
};

export const CreativeTableBody = styled(TableBody)(({ theme: t }) => ({
  'tr td': { borderBottom: 'none', borderRight: 'none' },
  'tr:first-of-type td': { borderTop: 'none' },
  'tr td:first-of-type': {
    borderRight: `4px solid ${t.palette.grey[200]} !important`,
  },
  'tr td:last-of-type': {
    borderLeft: `4px solid ${t.palette.grey[200]} !important`,
  },
}));

export const CreativeEmptyTable: FC = () => (
  <Typography variant="h1" sx={(t) => ({ color: t.palette.action.disabled, whiteSpace: 'nowrap' })}>
    No results
  </Typography>
);

export const TextDisplayValue = memo(({displayValue, children}: {displayValue: string; children?: React.ReactNode}) => (
  <Typography
    variant="body2"
    sx={{
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }}
  >
    {displayValue}
    {children}
  </Typography>
));
TextDisplayValue.displayName = 'TextDisplayValue';

export const CreativeNotes = memo(({ creativeId, displayValue }: { creativeId: number; displayValue: string }) => {
  const [isShow, setShow] = useState(false);
  const { onShowAlert } = useEnqueueSnackbar();
  const { reset, setValue, getValues } = useFormContext()

  const updateCreative = useUpdateCreative({
    config: {
      onSuccess: () => {
        onShowAlert('Changes saved');
        setShow((prev) => !prev);
        reset()
      },
      onError: () => {
        onShowAlert('Error');
        setValue(`notes.creative${creativeId}`, '');
        setShow((prev) => !prev);
      },
    },
  });

  const handleToggle = useCallback(() => {
    setValue(`notes.creative${creativeId}`, displayValue);
    setShow((prev) => !prev);
  }, [setShow, setValue, creativeId, displayValue]);

  const handleBlur = useCallback(() => {
    const notes: string = getValues<string>(`notes.creative${creativeId}`) as string
    updateCreative.mutate({
      id: String(creativeId),
      notes,
    });
  }, [updateCreative, creativeId, getValues]);

  return (
    <>
      {isShow ? (
        <Input
          autoFocus
          onBlur={handleBlur}
          name={`notes.creative${creativeId}`}
          multiline
          defaultValue={displayValue}
          disabled={updateCreative.isPending}
        />
      ) : (
        <TextDisplayValue displayValue={displayValue}>
          <IconButton
            onClick={handleToggle}
            className={'toggleVisibility'}
            sx={t => ({ fontSize: t.typography.body2.fontSize })}>
            <Create />
          </IconButton>
        </TextDisplayValue>
      )}
    </>
  );
});
CreativeNotes.displayName = 'CreativeNotes';

export const DisplayTableCellValue: FC<{displayValue: string | number; columnKey: AllColumnsKey; creative: CreativeTableItem}> = ({displayValue, creative, columnKey}) => {
  const { onShowAlert } = useEnqueueSnackbar();

  const handleCopyValue = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      onShowAlert('Copied');
    } catch (err) {
      onShowAlert('Error occurs');
    }
  };

  if( columnKey === 'file_name' ) {
    return (
      <>
        <Box className={'toggleVisibility'}>
          <Link
            target={'_blank'}
            href={creative.asana_task}
            sx={(t) => ({
              textDecoration: 'none',
              px: 1,
              py: 0.5,
              display: 'inline-flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 0.5,
              fontWeight: 500,
              fontSize: t.typography.body2.fontSize,
              borderRadius: 2,
              backgroundColor: 'rgba(255, 234, 237, 1)',
              color: 'rgba(106, 0, 50, 1)',
            })}
          >
            <WorkspacesIcon sx={{ fontSize: 16, color: 'rgba(254, 88, 74, 1)' }} />
            asana
          </Link>
        </Box>
        <TextDisplayValue displayValue={displayValue as string} />
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', visibility: 'hidden' }}
          className={'toggleVisibility'}
        >
          <Button
            startIcon={<ContentCopyIcon fontSize="small" />}
            sx={{ textTransform: 'capitalize', mr: 2, borderRadius: '4px' }}
            onClick={() => handleCopyValue(creative.file_name)}
          >
            Name
          </Button>
          <Button
            startIcon={<ContentCopyIcon fontSize="small" />}
            sx={{ textTransform: 'capitalize', borderRadius: '4px' }}
            onClick={() => handleCopyValue(`${location.origin}/creatives/${creative.id}`)}
          >
            Link
          </Button>
        </Box>
      </>
    )
  }

  if( columnKey === 'notes' ) return <CreativeNotes creativeId={creative.id} displayValue={displayValue as string} />

  return <TextDisplayValue displayValue={displayValue as string} />
}
